import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../../services/ContactService';

@Component({
  selector: 'app-index-onepage',
  templateUrl: './index-onepage.component.html',
  styleUrls: ['./index-onepage.component.css'],
})

/**
 * Onepage Component
 */
export class IndexOnepageComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    private offcanvasService: NgbOffcanvas,
    private fb: FormBuilder,
    private contactService: ContactService
  ) {}

  isCondensed = false;
  currentSection = 'home';
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = false;
  /**
   * Client Testimonial Data
   */
  testimonialData = [
    {
      profile: 'assets/images/client/01.jpg',
      name: 'Thomas Israel',
      designation: 'C.E.O',
      message: `" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "`,
    },
    {
      profile: 'assets/images/client/02.jpg',
      name: 'Barbara McIntosh',
      designation: 'M.D',
      message: `" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others. "`,
    },
    {
      profile: 'assets/images/client/03.jpg',
      name: 'Carl Oliver',
      designation: 'P.A',
      message: `" The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "`,
    },
    {
      profile: 'assets/images/client/04.jpg',
      name: 'Christa Smith',
      designation: 'Manager',
      message: `" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero. "`,
    },
    {
      profile: 'assets/images/client/05.jpg',
      name: 'Dean Tolle',
      designation: 'Developer',
      message: `" There is now an abundance of readable dummy texts. These are usually used when a text is required. "`,
    },
    {
      profile: 'assets/images/client/06.jpg',
      name: 'Jill Webb',
      designation: 'Designer',
      message: `" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. "`,
    },
  ];

  /**
   * Services Data
   */
  servicesData = [
    {
      icon: 'uil uil-file-search-alt h1 text-primary',
      title: 'Configure the event',
      description:
        'Choose start and end date so that tickets can be validated at the right time only.',
    },
    {
      icon: 'uil uil-edit-alt h1 text-primary',
      title: 'Create tickets as QR codes',
      description:
        'Create digital tickets for your event and distribute them as you prefer.',
    },
    {
      icon: 'uil uil-vector-square h1 text-primary',
      title: 'Validate',
      description:
        "When the event starts use a smartphone to validate the tickets, that's all, for real.",
    },
    // {
    //   icon: "uil uil-airplay h1 text-primary",
    //   title: "Easy To Use",
    //   description: "Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam eleifend aenean elementum semper."
    // },
    // {
    //   icon: "uil uil-calendar-alt h1 text-primary",
    //   title: "Daily Reports",
    //   description: "Allegedly, a Latin scholar established the origin of the established text by compiling unusual word."
    // },
    // {
    //   icon: "uil uil-clock h1 text-primary",
    //   title: "Real Time Zone",
    //   description: "It seems that only fragments of the original text remain in only fragments the Lorem Ipsum texts used today."
    // }
  ];

  /**
   * Pricing Data
   */
  pricingData = [
    {
      title: 'Usage based',
      price: 0.2,
      list: ['Unlimited events', 'Unlimited tickets'],
      btn: 'Login',
    },
  ];

  /**
   * Member Data
   */
  memberData = [];
  //   {
  //     profile: "assets/images/client/01.jpg",
  //     list: ["facebook", "instagram", "twitter", "linkedin"],
  //     name: "Ronny Jofra",
  //     designation: "C.E.O"
  //   },
  //   {
  //     profile: "assets/images/client/04.jpg",
  //     list: ["facebook", "instagram", "twitter", "linkedin"],
  //     name: "Micheal Carlo",
  //     designation: "Director"
  //   },
  //   {
  //     profile: "assets/images/client/02.jpg",
  //     list: ["facebook", "instagram", "twitter", "linkedin"],
  //     name: "Aliana Rosy",
  //     designation: "Manager"
  //   },
  //   {
  //     profile: "assets/images/client/03.jpg",
  //     list: ["facebook", "instagram", "twitter", "linkedin"],
  //     name: "Sofia Razaq",
  //     designation: "Developer"
  //   }
  // ];

  contactForm: FormGroup;

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required],
    });
  }

  mapView(mapcontent) {
    this.modalService.open(mapcontent, {
      windowClass: 'dark-modal',
      size: 'lg',
      centered: true,
    });
  }

  /**
   * Open modal for show the video
   * @param content content of modal
   */
  openWindowCustomClass(content) {
    this.modalService.open(content, {
      windowClass: 'dark-modal',
      size: 'lg',
      centered: true,
    });
  }
  openTrialModal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  /**
   * Toggle menu
   */
  toggleMenu() {
    this.isCondensed = !this.isCondensed;
    if (this.isCondensed) {
      document.getElementById('navigation').style.display = 'block';
    } else {
      document.getElementById('navigation').style.display = 'none';
    }
  }

  /**
   * Window scroll method
   */
  // tslint:disable-next-line: typedef
  windowScroll() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.getElementById('topnav').classList.add('nav-sticky');
    } else {
      document.getElementById('topnav').classList.remove('nav-sticky');
    }

    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      document.getElementById('back-to-top').style.display = 'inline';
    } else {
      document.getElementById('back-to-top').style.display = 'none';
    }
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  // Demo Offcanvas
  openright(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { position: 'end' });
  }

  onSubmit(): void {
    if (this.contactForm.valid) {
      this.contactService
        .createContactMessage(
          this.contactForm.value.name,
          this.contactForm.value.email,
          this.contactForm.value.message
        )
        .subscribe(() => {
          this.contactForm.reset();
          this.contactForm.markAsPristine();
          this.contactForm.markAsUntouched();
        });
    }
  }

  openInNewTab(url: string) {
    window.open(url, '_blank');
  }
}
